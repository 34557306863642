import React from "react";
import Helmet from "react-helmet";
import { Layout } from "../components/Layout";
import SEO from "../components/seo";

const IndexPage = () => (
    <Layout>
        <Helmet>
            <title>Success Page</title>
            <meta name="description" content="Success Page" />
        </Helmet>
        <SEO title="Success" />
        <p>Thank you!</p>
        <p>
            {" "}
            The human on other end of this form is quite busy, but it will come
            back to you... eventually.
        </p>
    </Layout>
);

export default IndexPage;
